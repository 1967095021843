// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

$(document).ready(function ()
{
	$('.go-up').click(function(){
		$('html,body').animate({scrollTop: 0}, 300);
		return false;
	});


	/*
	 * Productfiche
	 */
	 $('#product_form').submit(function(e)
	 {
	 	e.preventDefault();

	 	var pdf = $(this).attr('data-pdf');

	 	var confirm = $(this).find("#confirm").val();
	 	var email = $(this).find("#email").val();

		$('#product_form .alert').addClass('d-none');

	 	$.ajax({
			url: "/api/v2/1/nl/subscribe/2",
			method: "POST",
			data: { email : email },
		})
		.done(function( msg ) {
			$('#product_form').addClass('d-none');
			$('#product_form_success').removeClass('d-none');
		})
		.fail(function( jqXHR, textStatus ) {
			$('#product_form .alert').removeClass('d-none');
		});
	 })




	/*
	 *	MOBILE NAV
	 */

	$('.subnav').hide();

	$('.subnav-toggler').click(function(){
		$(this).toggleClass('toggled');
		$('.subnav', $(this).parent()).slideToggle(200);
	});

	/*
	 *	SLIDERS
	 */
	$('.slider-logos').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
  		autoplaySpeed: 8000,
  		prevArrow: '<button type="button" class="slick-prev" aria-label="previous"><i class="icon-angle-left"></i></button>',
  		nextArrow: '<button type="button" class="slick-next" aria-label="next"><i class="icon-angle-right"></i></button>',
  		responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 3,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 2,
			}
		}]
	});

	$('.slider-detail').slick({
		infinite: true,
  		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
  		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>'
	})
	.on('beforeChange', function(event, slick, currentSlide, nextSlide){
		$('.detail-thumb').removeClass('active');
		$('.detail-thumb').eq(nextSlide).addClass('active');
	});

	$('.detail-thumb').click( function(){
		index = $(this).index();
		$('.slider-detail').slick('slickGoTo', parseInt(index));
	});

	$('.banner-slider').slick({
		infinite: true,
  		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
  		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>'
	});

	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});
});

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});